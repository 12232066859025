import dynamic from 'next/dynamic';
import React, { PropsWithChildren } from 'react';

const NonSSRWrapper: React.FC<PropsWithChildren> = ({ children }) => (
  // eslint-disable-next-line react/jsx-no-useless-fragment
  (<>{children}</>)
);

export default dynamic(() => Promise.resolve(NonSSRWrapper), {
  ssr: false,
});
